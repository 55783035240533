<template>
    <v-row v-if="!loading">
        <v-col cols="10">
            <v-card
                v-for="month in availableMonths"
                :key="month.value"
                class="mb-4"
                v-show="findVersionByMonth(month.value).length > 0"
            >
                <v-card-title class="justify-center">{{ month.label }} {{ year }}</v-card-title>
                <v-card-text>
                    <v-list two-line>
                        <v-list-item
                            v-for="version in findVersionByMonth(month.value)"
                            :key="version.id"
                            @click="openVersion(version)"
                        >
                            <v-list-item-icon>
                                <v-icon v-text="'mdi-' + version.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    class="subtitle-1"
                                    v-text="`${version.label}: ${version.name}`"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                    v-text="formatDate(version.date, 'dd MMM')"
                                ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-chip :color="color(version.status)" dark label>
                                    <v-icon left dark v-if="version.status === 'major'">mdi-check-decagram</v-icon>
                                    <v-icon left dark v-if="version.status === 'minor'">mdi-alert-decagram</v-icon>
                                    <v-icon left dark v-if="version.status === 'fix'">mdi-decagram</v-icon>
                                    {{ $t('lists.versionStatus.' + version.status) }}
                                </v-chip>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card class="mb-6">
                <v-card-text>
                    <v-subheader class="text-uppercase">{{ $t('calendar.filters.year') }}</v-subheader>
                    <v-select v-model="year" :items="availableYears" dense></v-select>
                    <v-subheader class="text-uppercase">{{ $t('calendar.filters.sorting') }}</v-subheader>
                    <v-radio-group v-model="sorting">
                        <v-radio :label="$t('calendar.filters.sort.asc')" value="asc"></v-radio>
                        <v-radio :label="$t('calendar.filters.sort.desc')" value="desc"></v-radio>
                    </v-radio-group>
                    <v-subheader class="text-uppercase">{{ $t('calendar.filters.status') }}</v-subheader>
                    <v-container fluid class="pa-0 pt-4">
                        <v-row class="justify-center">
                            <v-col class="py-0" cols="12" v-for="s in versionStatus" :key="s">
                                <v-checkbox
                                    v-model="status"
                                    :label="$t('lists.versionStatus.' + s)"
                                    :value="s"
                                    class="pt-0 mt-0"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-subheader class="text-uppercase">{{ $t('calendar.filters.inDevelop') }}</v-subheader>
                    <v-switch v-model="development" :label="$t('projects.versions.development')"></v-switch>
                    <v-subheader class="text-uppercase">{{ $t('calendar.filters.products') }}</v-subheader>
                    <v-radio-group v-model="filterProduct">
                        <v-radio :label="$t('projects.all')" :value="true"></v-radio>
                        <v-radio :label="$t('calendar.filters.project')" :value="false"></v-radio>
                    </v-radio-group>
                    <selector-projects v-show="!filterProduct" v-model="projectsToFilters" />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { API } from '@/utils/api';
import { formatDateMixin } from '@/mixins/dates';
import SortBy from 'lodash/sortBy';
import Range from 'lodash/range';
import UpperFirst from 'lodash/upperFirst';
import startOfToday from 'date-fns/startOfToday';
import startOfYear from 'date-fns/startOfYear';
import addMonths from 'date-fns/addMonths';
import selectorProjects from '@/components/selectors/multiple/projects';

export default {
    name: 'calendar',
    data: () => ({
        loading: false,
        versions: [],
        year: new Date().getFullYear(),
        status: ['major'],
        development: false,
        sorting: 'desc',
        filterProduct: true,
        projectsToFilters: [],
    }),
    mixins: [formatDateMixin],
    components: { selectorProjects },
    created() {
        this.fetchVersions();
    },
    watch: {
        year: function () {
            this.fetchVersions();
        },
    },
    computed: {
        ...mapGetters(['projects', 'versionStatus']),
        availableYears() {
            return Range(2014, new Date().getFullYear() + 2, 1).reverse();
        },
        months() {
            let months = Range(0, 12, 1).map((m) => {
                return {
                    value: m,
                    label: UpperFirst(this.formatDate(addMonths(startOfYear(startOfToday()), m), 'MMMM')),
                };
            });

            return this.sorting === 'desc' ? months.reverse() : months;
        },
        availableMonths() {
            const allowMonth = this.versions.map((v) => new Date(v.date).getMonth());
            return this.months.filter((m) => {
                return allowMonth.includes(m.value);
            });
        },
    },
    methods: {
        async fetchVersions() {
            this.loading = true;

            const versions = await API.post('analytics/projects/versions/year', { year: this.year });

            this.versions = versions.data.datas;

            this.loading = false;
        },
        findVersionByMonth(month) {
            return SortBy(
                this.versions.filter(
                    (v) =>
                        new Date(v.date).getMonth() === month &&
                        this.status.includes(v.status) &&
                        this.development === v.development &&
                        ((!this.filterProduct && this.projectsToFilters.includes(v.project_id)) || this.filterProduct),
                ),
                ['date', 'label', 'name'],
                ['asc', 'asc', 'asc'],
            );
        },
        color(status) {
            if (status === 'major') {
                return 'light-green darken-1';
            }
            if (status === 'minor') {
                return 'lime lighten-1';
            }
            return 'light-blue lighten-1';
        },
        openVersion(version) {
            this.$router.push({
                name: 'project-version',
                params: { project_id: version.project_id, version: version.id },
            });
        },
    },
};
</script>
