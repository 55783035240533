<template>
    <v-autocomplete
        v-model="localProjects"
        :items="projectsToDisplay"
        :label="label"
        multiple
        chips
        deletable-chips
    ></v-autocomplete>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'selector-projects',
    data: () => ({
        localProjects: [],
    }),
    created() {},
    mounted() {
        this.localProjects = this.value;
    },
    props: {
        value: {},
        label: {},
        type: {
            default: 'id',
        },
    },
    watch: {
        localProjects: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localProjects = value;
        },
    },
    computed: {
        ...mapGetters(['projects']),
        projectsToDisplay() {
            return this.projects
                .map((p) => {
                    return { text: p.label, value: this.type === 'id' ? p.id : p.name };
                })
                .sort((a, b) => {
                    return a.text.localeCompare(b.text);
                });
        },
    },
};
</script>
